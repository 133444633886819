
// Libraries
import * as React from 'react'
import Layout from '../../components/layout'
import InputFactory from '../../components/inputFactory'
import {useEffect, useState} from "react";

// Components
import Button from '../../components/button'
import Seo from '../../components/seo'

// Services
import UserService from '../../services/user'
import DuoSection from "../../components/duoSection";

// Middleware
import CheckoutMiddleware from '../../middleware/checkoutMiddleware'

const Checkout = () => {
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState();
	const [address, setAddress] = useState();
	const userService = new UserService();

	useEffect(async () => {
		setLoading(true);

		const usr = await userService.getUser();
		if(usr.success) {
			setLoading(false);
			setUser(usr.data)

			// find their primary address
			if (usr.data.addresses) {
				for(let i = 0; i < usr.data.addresses.length; i++) {
					let add = usr.data.addresses[0]
					if (add.is_primary) {
						setAddress(add)
						break
					}
				}
			}
		}
	}, [])

	return (
		<CheckoutMiddleware>
			<Layout className="nav-blue-half checkout">
				<Seo title="Checkout" />
				<DuoSection>
					<div>
					<h1 className="generic-template__title">Checkout</h1>

					</div>
					<div>
							<div className="checkout--colour-endeavour">
								<h2>Shipping<br/>Information</h2>
								<p>To maintain the security of the platform and ensure that only paid care workers
									receive tickets from successful ballot entries, we need your current residential
									address. This address will be used to send hard tickets by post if you win tickets
									for an event that does not support digital ticketing. You can edit your address
									up to 3 times from your profile. You may be asked to verify your address if you
									win.</p>

								{
									loading ? <p>Loading your shipping information...</p> :
										<>
											<InputFactory
												type="input"
												name="first-name"
												label="First Name*"
												value={user.first_name}
												readonly={'readonly'}
											/>

											<InputFactory
												type="input"
												name="last-name"
												label="Last Name*"
												value={user.last_name}
												readonly={'readonly'}
											/>

											<InputFactory
												type="input"
												name="address-1"
												label="Address*"
												value={address?.street}
												readonly={'readonly'}
												placeholder='Address line 1'
											/>

											<InputFactory
												type="input"
												name="address-2"
												label={false}
												value={address?.street_2}
												readonly={'readonly'}
												placeholder='Address line 2 (optional)'
											/>

											<InputFactory
												type="input"
												name="town-city"
												label="Town/city*"
												value={address?.city}
												readonly={'readonly'}
											/>

											<InputFactory
												type="input"
												name="county"
												label="County"
												value={address?.state}
												readonly={'readonly'}
											/>

											<InputFactory
												type="input"
												name="country"
												label="Country"
												value="United Kingdom"
												readonly={'readonly'}
											/>

											<InputFactory
												type="input"
												name="postcode"
												label="Postcode*"
												value={address?.postal_code}
												readonly={'readonly'}
											/>

											<Button to={'/basket/billing'}>Next</Button>
										</>
								}
							</div>
					</div>
					</DuoSection>
			</Layout>
		</CheckoutMiddleware>
	)
}

export default Checkout
